<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <div class="grid grid-cols-12 gap-4">
      <!-- database details -->
      <base-card title="Database details" class="col-span-6 mr-4">
        <base-button class="mb-2" @action="nodeDetails.editNode()" :loading="nodeDetails.loadHandler.states.edit_node"
          ><featherIcon class="w-5 h-5" icon="SaveIcon"
        /></base-button>
        <baseForm :handler="nodeDetails.editForm" />
      </base-card>

      <!-- provider details -->
      <div class="col-span-6 rounded h-full">
        <baseTabs :handler="tabs" v-show="!nodeDetails.loadHandler.somethingLoading.value" @changed="onTabChange">
          <template v-slot:panel-comments>
            <baseComments :commentable_id="route.params.objectId" commentable_type="node" :title="false" />
          </template>
          <template v-slot:panel-attachments>
            <baseAttachments :attachable_id="route.params.objectId" attachable_type="node" :title="false" />
          </template>
          <template v-slot:panel-provider_details>
            <base-code-viewer class="bg-white rounded shadow-lg p-4" :data="nodeDetails.data.value" />
          </template>
        </baseTabs>
      </div>

      <!-- <base-card title="Provider details" class="col-span-6 h-96 overflow-y-auto"> -->
      <!-- <base-code-viewer :data="nodeDetails.data.value" /> -->
      <!-- </base-card> -->

      <!-- map cards -->
      <!-- <base-card class="col-span-6 mr-4">{{ nodeDetails }}</base-card>
      <base-card class="col-span-6">{{ nodeDetails }}</base-card> -->
    </div>

    <div></div>

    <div class="flex flex-row my-4">
      <baseIntervalSelector></baseIntervalSelector>
    </div>

    <div class="flex flex-col mt-4">
      <!-- usage -->
      <base-card class="text-gray-400 w-full h-full mb-4">
        <div class="flex justify-center" v-if="showNoDataMessage && !nodeDetails.loadHandler.somethingLoading.value">
          <!-- <div>{{ $translate('No data') }}</div> -->
        </div>
        <div v-else class="flex justify-center">
          <baseLoader class="text-gray-500" :loading="nodeDetails.loadHandler.somethingLoading.value" />
        </div>
        <cardChart
          class="mb-4"
          v-if="!nodeDetails.loadHandler.somethingLoading.value && nodeDetails.chart_data_usage.value.length > 0"
          :options="nodeDetails.chart_options_usage.value"
          label="Usage"
          :exportUnit="useLookup.utilities[nodeDetails.data.value.medium.toLowerCase()].unit"
        />
        <div v-if="!nodeDetails.loadHandler.somethingLoading.value && !nodeDetails.chart_data_usage.value.length > 0">
          <div class="mb-2">Verbruik</div>
          <div class="text-sm">Geen data</div>
        </div>
      </base-card>

      <!-- production -->
      <base-card
        class="text-gray-400 w-full h-full"
        v-if="!['Gas', 'Heat', 'HeatCost', 'Water'].includes(nodeDetails.data.value.medium)"
      >
        <div class="flex justify-center" v-if="showNoDataMessage && !nodeDetails.loadHandler.somethingLoading.value">
          <!-- <div>{{ $translate('No data') }}</div> -->
        </div>
        <div v-else class="flex justify-center">
          <baseLoader class="text-gray-500" :loading="nodeDetails.loadHandler.somethingLoading.value" />
        </div>
        <cardChart
          v-if="
            !nodeDetails.loadHandler.somethingLoading.value && nodeDetails.chart_data_production_return.value.length > 0
          "
          :options="nodeDetails.chart_options_return.value"
          label="Return"
          :exportUnit="useLookup.utilities[nodeDetails.data.value.medium.toLowerCase()].unit"
        />
        <div
          v-if="
            !nodeDetails.loadHandler.somethingLoading.value &&
            !nodeDetails.chart_data_production_return.value.length > 0
          "
        >
          <div class="mb-2">Teruglevering</div>
          <div class="text-sm">Geen data</div>
        </div>
      </base-card>

      <!-- taken -->
      <base-page-title class="mt-12 mb-4" :title="'Taken'" :icon="'ToolIcon'"></base-page-title>

      <baseTable
        :fields="useTasks.taskFields.value"
        :records="useTasks.tasks.value"
        :records-per-page="15"
        :add-button="true"
        :edit-button="false"
        :delete-button="true"
        :clickAble="false"
        :loading="useTasks.loadHandler.states[`get_tasks`]"
        :selectable="false"
        :hover-effect="true"
        keyField="id"
        @add="useTasks.openAddTaskModal"
        @delete="deleteTaskWrapper"
        leadIcon="ToolIcon"
      />
    </div>

    <!-- add task modal -->
    <baseModal
      v-if="useTasks.showAddTaskModal.value"
      @close="useTasks.closeAddTaskModal()"
      :title="$translate('Execute')"
      size="s"
    >
      <baseForm :handler="useTasks.addForm" :loading="useTasks.loadHandler.states['create_task']" />
      <!-- add button -->
      <template v-slot:buttons>
        <baseButton
          @action="useTasks.createTask(nodeDetails.data.value.object_id, nodeDetails.data.value.provider_identifier)"
          :loading="useTasks.loadHandler.states[`create_task`]"
        >
          {{ $translate('Execute') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted, computed } from 'vue'
import nodesDetailsHandler from '@/use/viewHandlerNodeDetails'
import cardChart from '@/views/components/cardChart.vue'
import lookupHandler from '@/use/lookupHandler'
import tasksHandler from '@/use/taskHandler'
import commentHandler from '@/use/commentHandler'
import attachmentHandler from '@/use/attachmentHandler'
import tabHandler from '@/use/tabHandler'

export default {
  setup() {
    const route = useRoute()
    const nodeDetails = nodesDetailsHandler()
    const objectId = route.params.objectId
    const meta = computed(() => route.meta)
    const useLookup = lookupHandler()
    const useTasks = tasksHandler()
    const useComments = commentHandler()
    const useAttachments = attachmentHandler()
    const tabs = tabHandler()

    const tabOptions = computed(() => {
      return {
        tabs: [
          { id: 'comments', label: 'Opmerkingen', active: true },
          { id: 'attachments', label: 'Bijlagen', active: true },
          { id: 'provider_details', label: 'Provider details', active: true },
        ],
      }
    })

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!nodeDetails.loadHandler.states.get_details) {
        return `${nodeDetails.data.value.provider_identifier}`
      }
      return ''
    })

    const showNoDataMessage = computed(() => {
      const usage = nodeDetails.chart_data_usage.value
      const production = nodeDetails.chart_data_production_return.value

      // Return true if all of the arrays are empty
      return usage.length === 0 && production.length === 0
    })

    function deleteTaskWrapper(taskId) {
      useTasks.deleteTask(taskId, nodeDetails.data.value.object_id)
    }

    function onTabChange() {
      // pass
    }

    onMounted(() => {
      nodeDetails.getDetails(objectId)
      useTasks.getTasks(objectId, 'node')

      tabs.create(tabOptions.value)

      useComments.list({ commentable_id: objectId, commentable_type: 'node' })
      useAttachments.list({ attachable_id: objectId, attachable_type: 'node' })
    })

    return {
      nodeDetails,
      objectId,
      pageTitle,
      pageSuffix,
      meta,
      showNoDataMessage,
      useLookup,
      useTasks,
      deleteTaskWrapper,
      route,
      tabs,
      onTabChange,
    }
  },
  components: {
    cardChart,
  },
}
</script>
