import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'
import toastHandler from '@/use/toastHandler'

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()
  const useToast = toastHandler()

  const fields = ref([
    {
      label: 'Object ID',
      key: 'user_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'email',
      searchable: true,
      required: true,
    },
    {
      label: 'Auth0 id',
      key: 'auth0_user_id',
      type: 'string',
      add: false,
      edit: false,
    },
    {
      label: 'Active org',
      key: 'organisation',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Password',
      key: 'password',
      type: 'password',
      edit: false,
      table: false,
      required: true,
    },
    {
      label: 'Role',
      key: 'role',
      type: 'select',
      options: selectValues.role,
      table: false,
      searchable: true,
    },
  ])

  function addOrganisationNameToUsers(users) {
    return users.map((user) => {
      if (user.active_org_id && user.organisations && user.organisations.length > 0) {
        const activeOrg = user.organisations.find((org) => org.object_id === user.active_org_id)
        if (activeOrg) {
          return {
            ...user,
            organisation: activeOrg.name,
          }
        }
      }
      return user
    })
  }

  // list
  function getRecords() {
    loadHandler.setLoadingState('get_records', true)

    useApi
      .requestV2('get', 'v1/users/')
      .then((response) => {
        if (response.data) {
          const parsedResponse = addOrganisationNameToUsers(response.data)
          records.value = parsedResponse
        }
        loadHandler.setLoadingState('get_records', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('get_records', false)
      })
  }

  // sync
  function sync() {
    loadHandler.setLoadingState('sync', true)

    useApi
      .requestV2('get', 'v1/users/sync')
      .then(() => {
        loadHandler.setLoadingState('sync', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('sync', false)
      })
  }

  // add
  function addRecord() {
    const payload = addForm.data.value
    loadHandler.setLoadingState('add_record', true)

    useApi
      .requestV2('post', 'v1/users/', payload)
      .then(() => {
        loadHandler.setLoadingState('add_record', false)
        showAdd.value = false
        getRecords()
      })
      .catch((error) => {
        if (error.request.status === 409) {
          useToast.addToast(`Gebruiker met email ${payload.email} bestaat al`, 'danger')
        }
        loadHandler.setLoadingState('add_record', false)
      })
  }

  // open edit
  function openEdit(data) {
    useApi
      .requestV2('get', 'v1/users/roles')
      .then((response) => {
        const parsedRoles = Object.values(response.data).map((role) => ({
          label: role.name,
          value: role.id,
        }))

        fields.value[5].options = parsedRoles
        loadHandler.setLoadingState('get_roles', false)
      })
      .finally(() => {
        editForm.create(
          {
            type: 'edit',
            fields: fields.value,
            data: data,
          },
          'user_id'
        )
      })

    showEdit.value = true
  }

  function openAdd() {
    loadHandler.setLoadingState('get_roles', true)
    useApi
      .requestV2('get', 'v1/users/roles')
      .then((response) => {
        const parsedRoles = Object.values(response.data).map((role) => ({
          label: role.name,
          value: role.id,
        }))

        fields.value[5].options = parsedRoles
        fields.value[5].default = parsedRoles[0].value

        loadHandler.setLoadingState('get_roles', false)
      })
      .finally(() => {
        addForm.create({
          type: 'add',
          fields: fields.value,
          data: {},
        })
      })

    showAdd.value = true
  }

  function closeAdd() {
    addForm.data.value = {}
    showAdd.value = false
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
  }

  // edit
  function editRecord() {
    const payload = editForm.getData('user_id')
    const user_id = payload.user_id

    loadHandler.setLoadingState('edit_record', true)

    useApi.requestV2('patch', `v1/users/${user_id}`, payload).then(() => {
      loadHandler.setLoadingState('edit_record', false)
      closeEdit()
      getRecords()
    })
  }

  // delete
  function deleteRecord(value) {
    loadHandler.setLoadingState('delete_record', true)
    useApi.requestV2('delete', `v1/users/${value}`).then(() => {
      loadHandler.setLoadingState('delete_record', false)
      getRecords()
    })
  }

  return {
    records,
    getRecords,
    addRecord,
    fields,
    addForm,
    editForm,
    showAdd,
    showEdit,
    openEdit,
    openAdd,
    editRecord,
    deleteRecord,
    loadHandler,
    closeAdd,
    closeEdit,
    sync,
  }
}
